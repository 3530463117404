<template>
  <div class="info" v-loading="loading">
    <img src="http://www.njxcedu.com/pic/teacher_banner.jpg" width="100%">
    <div class="teacherForm">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入申请人姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="ruleForm.gender">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNo">
          <el-input v-model="ruleForm.idNo" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" type="number"></el-input>
        </el-form-item>
        <el-form-item label="所在机构名称" prop="agency">
          <el-input v-model="ruleForm.agency" placeholder="请输入所在机构名称"></el-input>
        </el-form-item>
        <el-form-item label="所教科目" prop="item">
          <el-input v-model="ruleForm.item" placeholder="请输入所教科目"></el-input>
        </el-form-item>
        <el-form-item prop="photo">
          <label slot="label"><span style="color:#F56C6C">*</span> 上传照片</label>
          <el-upload
            class="avatar-uploader"
            ref="upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            action="#"
            :auto-upload="false"
            :on-change="picPreview"
            :http-request="addTeacher"
            v-model="ruleForm.photo">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-button type="primary" style="width:335px;margin: 20px 0;" @click="submitForm('ruleForm')">马上提交</el-button>
      </el-form>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="80%" :showClose="false">
      <span style="font-size:16px">{{dialogMessage}}</span><br>
      <el-button type="primary" style="text-align:center;margin-top:30px" @click="dialogVisible = false">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import teacherApi from '../api/teacher.js'

export default {
  data() {
    const checkIdNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证不能为空'))
      }
      var pass = false
      if(value.length == 18){
        value = value.split('');
        var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
        var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
        var sum = 0;
        var ai = 0;
        var wi = 0;
        for (var i = 0; i < 17; i++)
        {
          ai = value[i];
          wi = factor[i];
          sum += ai * wi;
        }
        var last = parity[sum % 11];
        if(last == value[17]){
          pass = true
        }
      }
      if (!pass) {
        callback(new Error('请输入正确的二代身份证号码'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      dialogMessage: '',
      loading: false,
      baseURL: process.env.BASE_API,
      imageUrl: '',
      ruleForm: {
        name: '',
        gender: '',
        idNo: '',
        phone: '',
        agency: '',
        item: ''
      },
      file:{},
      rules: {
        name: [
          { required: true, message: '请输入申请人姓名', trigger: 'change' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        idNo: [
          { required: true, message: '请输入身份证号', trigger: 'change' },
          { validator: checkIdNum, trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: 'change' }
        ],
        agency: [
          { required: true, message: '请输入所在机构名称', trigger: 'change' }
        ],
        item: [
          { required: true, message: '请输入所教科目', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    picPreview(file) {
      var URL = null
      if(window.createObjectURL != undefined){
        URL = window.createObjectURL(file.raw)
      }else if(window.URL != undefined){
        URL = window.URL.createObjectURL(file.raw)
      }else if(window.webkitURL != undefined){
        URL = window.webkitURL.createObjectURL(file.raw)
      }
      this.imageUrl = URL
      this.file = file
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if(this.imageUrl == '') {
            this.dialogVisible = true
            this.dialogMessage = '请上传照片'
          }else{
            this.$refs.upload.submit()
          }
        }
      });
    },
    addTeacher() {
      var that = this
      that.loading = true
      const formData = new FormData()
      formData.append("photo", this.file.raw)
      formData.append("name", this.ruleForm.name)
      formData.append("gender", this.ruleForm.gender)
      formData.append("idNo", this.ruleForm.idNo)
      formData.append("phone", this.ruleForm.phone)
      formData.append("agency", this.ruleForm.agency)
      formData.append("item", this.ruleForm.item)
      teacherApi.addTeacher(formData).then(res => {       
        that.loading = false 
        if(res.data.success){
          that.$router.push({ path:'/uploadOk'})
        }else if(res.data.data.info == 20009){
          that.dialogVisible = true
          that.dialogMessage = '该用户已存在，请勿重复操作'
        } else {
          that.dialogVisible = true
          that.dialogMessage = '网络出错，请联系管理员'
        }
      }).catch(function (error) {})
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.dialogVisible = true
        this.dialogMessage = '上传头像图片只能是 JPG/PNG 格式!'
      }
      if (!isLt2M) {
        this.dialogVisible = true
        this.dialogMessage = '上传头像图片大小不能超过 2MB!'
      }
      return isJPG && isLt2M;
    }
  },
  
}
</script>

<style scoped>
.info {
  width: 100%;
  margin: 0 auto;
}
.teacherForm {
  margin: 0 auto;
  padding: 10px 20px;
}
.teacherForm .el-form-item{
  text-align: left;
  float: none;
  margin-bottom: 10px;
}
.teacherForm >>> .el-form-item__label {
  float: none;
}
.avatar-uploader {
  width: 178px;
  border: 1px dashed #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #8c939d;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>>